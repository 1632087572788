import shoot_income_protection from '../../assets/income-protection-shot.jpg';
import FaqComponent from 'react-faq-component';

function Coverage() {
    const data = {
        title: "",
        rows: [
            {
                title: "Dismissal from work",
                content: `<ul> <li>If you lose your job for reasons out of your control</li> <li>If there are company-wide lay-offs</li> </ul>`
            },
            {
                title: "Business Closure",
                content: `<ul> <li>If your employer closes down the business</li> </ul>`
            }
        ]
    }
    return (
      <>
  {/* shot cover section  */}
        <section className="immersive-section xl:hidden">
            <div className="immersive-image"><img src={shoot_income_protection} loading="lazy" alt="casava insurance lady image shot" /></div>
        </section>

        {/* coverage section */}
        <section className="immersive-section income-policy-details-section">
            <div className="container">
                <div className="flex justify-end">
                    <div className="w-full md:w-2/4">
                        <h2 className="lg:mt-12">Know whats <br /> covered</h2>
                        <div className="income-policy-card">
                            <p className="text-xl mb-10">You can make a claim if this ever happens</p>
                            
                            <FaqComponent
                                data={data}
                            />

                            <a href="https://casava-dev.s3.eu-central-1.amazonaws.com/policy-documents/post-purchase/income_protection_generic2022-10-18%2010%3A29%3A31-.pdf" target="_blank" className="button button--black mt-10"><span>Download Sample Policy</span></a>
                            <p className="opacity-50 mt-6"><small>Income protection doesn't cover willful resignation from work, misconduct or delays in payment of your salary</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="immersive-image hidden xl:block"><img src={shoot_income_protection}loading="lazy" alt="casava insurance lady image shot" /></div>
        </section>
        </>
        );
      }
      
      export default Coverage;