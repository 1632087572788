import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";
import Cover from "../components/Partners/Cover";
import InfoBanner from "../components/Common/InfoBanner";
import Banner from "../components/Common/Banner";
import partner_page_commission_shot from '../assets/partner-page-commission-shot.png';
import partner_page_grow_convert_shot from '../assets/partner-page-grow-convert-shot.png';
import icon_partners_access from '../assets/icon-partners-access.png';
import brand_logo_1 from '../assets/brand-logo-01.png';
import brand_logo_2 from '../assets/brand-logo-02.png';
import brand_logo_3 from '../assets/brand-logo-03.png';
import brand_logo_4 from '../assets/brand-logo-04.png';
import brand_logo_5 from '../assets/brand-logo-05.png';
import brand_logo_6 from '../assets/brand-logo-06.png';


function Partners() {

  let infoProps =  {
      "field1":"Apply for access",
      "desc1":"Access various levels of integration and" ,
      "desc2":"customization for your platform.",
      "img":icon_partners_access
  }

  return (
    <div>
      <Header></Header>

      <Cover></Cover>
      
      <section className="fature-section light-pink--background">
            <div className="container">
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20 items-center">
                    <div className="flex flex-col lg:ml-auto order-first">
                        <h1 className="mb-6">Get up to 20% <br /> in commission </h1>
                        <p className="text-xl">We provide standard commissions for your sales volume. Sell more products and enjoy amazing benefits.</p>
                        <a href="https://forms.gle/8JPdMqxaX1xZXEK38" target="_blank" className="button button--black mt-10 self-start"><span>Partner with us</span></a>
                    </div>
                    <div className="image-layout order-first lg:order-last scale-img"><img src={partner_page_commission_shot} alt="casava ai claims illustration image" /></div>
                </div>
            </div>
        </section>


        <section className="fature-section no-padding">
            <div className="container modified-container purple--background">
                <div className="grid grid-cols-1 items-center">
                    <div className="text-center order-first">
                        <h1 className="mb-6">Grow, convert <br /> and retain</h1>
                        <p className="text-xl">We are giving you access to digital <br /> insurance that's valuable to your customers <br /> and inturn, yields business growth.</p>
                    </div>
                    <div className="image-layout order-first lg:order-last"><img src={partner_page_grow_convert_shot} alt="casava ai claims illustration image" /></div>
                </div>
            </div>
        </section>


        <section className="fature-section integration-cover light-green--background">
            <div className="container">
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20 items-center">
                    <div className="flex flex-col lg:ml-auto">
                        <h1 className="mb-6">Seamless <br /> integration with <br /> massive value</h1>
                        <p className="text-xl">We provide flexible APIs and we configure the sales and claims process to align with your business needs.</p>
                    </div>
                </div>
            </div>
        </section>


        <section className="fature-section black--background">
            <div className="container modified-container">
                <div className="grid gap-10 grid-cols-1 lg:gap-20 items-center">
                    <div className="text-center">
                        <h1 className="mb-6">We cover all <br /> businesses</h1>
                        <p className="text-xl opacioty-half">Focus on enjoying life while we focus on providing a <br /> cover for the things you love.</p>
                        <a href="https://forms.gle/8JPdMqxaX1xZXEK38" target="_blank" className="button button--pink mt-10 self-start"><span>Partner with us</span></a>
                    </div>
                </div>
            </div>
        </section>

        <section className="no-padding">
            <div className="container-fluid">
                <div className="content-tiles">
                    <a href="" className="tile tile-1">
                        <span>Digital banks</span>
                    </a>
                    <a href="" className="tile tile-2">
                        <span>Government Agency</span>
                    </a>
                    <a href="" className="tile tile-3">
                        <span>Resellers &amp; Agent Networks</span>
                    </a>
                    <a href="" className="tile tile-4">
                        <span>Employers &amp; HR Companies</span>
                    </a>
                </div>
            </div>
        </section>


        <section>
          <div className="brands-section">
            <div className="container">
                <div className="flex flex-col lg:flex-row items-center justify-between">
                    <div className="hospitals-carousel-container w-full">
                        <div className="hospitals-carousel__wrapper brand_logos">
                            <div className="carousel-item"><img src={brand_logo_1} alt="" /></div>
                            <div className="carousel-item"><img src={brand_logo_2} alt="" /></div>
                            <div className="carousel-item"><img src={brand_logo_3} alt="" /></div>
                            <div className="carousel-item"><img src={brand_logo_4} alt="" /></div>
                            <div className="carousel-item"><img src={brand_logo_5} alt="" /></div>
                            <div className="carousel-item"><img src={brand_logo_6} alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
              <div className="text-center">
                <p>Give your customers and employees <br /> insurance coverage in real-time</p>
              </div>
            </div>
          </div>
        </section>

      <section className="visble-cta">
        <InfoBanner infoProps={infoProps}></InfoBanner>
      </section>

      <Banner></Banner>
      <Footer></Footer>
    </div>
  );
}

export default Partners;
