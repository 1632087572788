import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";
import Cover from "../components/Business/Cover";
import Faq from "../components/Common/Faq";
import faqData from '../config/faq.json';
import Banner from "../components/Common/Banner";
import partner_portal_shot_1 from '../assets/partner-portal-shot-1.png';
import partner_portal_shot_2 from '../assets/partner-portal-shot-2.png';
import brand_logo_1 from '../assets/brand-logo-01.png';
import brand_logo_2 from '../assets/brand-logo-02.png';
import brand_logo_3 from '../assets/brand-logo-03.png';
import brand_logo_4 from '../assets/brand-logo-04.png';
import brand_logo_5 from '../assets/brand-logo-05.png';
import brand_logo_6 from '../assets/brand-logo-06.png';


function Business() {
  let index  = Math.ceil(faqData["healthCashData"]['rows'].length/2);

  let rows = faqData["healthCashData"].rows;
  let data1 = {title:'',rows:[]};
  data1.rows = rows.slice(0,index);

  let data2 = {title:'',rows:[]};
  data2.rows = rows.slice(index,faqData["healthCashData"]['rows'].length);


  return (
    <div>
      <Header></Header>
      <Cover></Cover>

      <section className="fature-section">
          <div className="container">
              <div className="grid grid-cols-1 items-center">
                  <div className="text-center order-first">
                      <p className="text-xl">
                        <strong>Trusted by a diverse range of businesses</strong>
                      </p>
                  </div>
                  <div className="image-layout order-first mt-10 lg:order-last">
                    <ul className="brand-logo">
                      <li>
                        <img src={brand_logo_1} alt="" />
                      </li>
                      <li>
                        <img src={brand_logo_2} alt="" />
                      </li>
                      <li>
                        <img src={brand_logo_3} alt="" />
                      </li>
                      <li>
                        <img src={brand_logo_4} alt="" />
                      </li>
                      <li>
                        <img src={brand_logo_5} alt="" />
                      </li>
                      <li>
                        <img src={brand_logo_6} alt="" />
                      </li>
                    </ul>
                  </div>
              </div>
          </div>
      </section>

      <section className="fature-section no-padding seamless-integration">
          <div className="container modified-container purple--background business-first">
              <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20">
                  <div className="order-first">
                      <h1 className="mb-6">Seamless <br /> integration with <br /> massive value</h1>
                      <p className="text-xl">Our products are integrated seamlessly into <br /> any digital platform or website</p>
                      <a href="" className="button button--white mt-10 self-start"><span>Browse Products</span></a>
                  </div>
                  <div className="order-first lg:order-last"><img src={partner_portal_shot_1} alt="casava ai claims illustration image" /></div>
              </div>
          </div>
      </section>

      <section className="fature-section no-padding seamless-integration integration2">
          <div className="container modified-container white--background business-second">
              <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20"> 
                  <div className="seamless-mob-shot"></div>
                  <div className="">
                      <h1 className="mt-10 mb-6">Seamless <br /> integration with <br /> massive value</h1>
                      <p className="text-xl">Our products are integrated seamlessly into <br /> any digital platform or website</p>
                      <a href="" className="button button--pink mt-10 self-start"><span>Browse Products</span></a>
                  </div>
              </div>
          </div>
      </section>

      <section className="fature-section no-padding seamless-integration integration2">
          <div className="container modified-container white--background business-third">
              <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20"> 
                  <div className="mt-10">
                      <h1 className="mt-24 mb-6">Seamless <br /> integration with <br /> massive value</h1>
                      <p className="text-xl">Our products are integrated seamlessly into <br /> any digital platform or website</p>
                      <a href="" className="button button--pink mt-10 self-start"><span>Browse Products</span></a>
                  </div>
                  <div className="seamless-mob-shot2"></div>
              </div>
          </div>
      </section>

      <section className="fature-section light-gray--background">
          <div className="container">
              <div className="grid grid-cols-1 items-center">
                  <div className="text-center order-first">
                      <h1 className="mb-6">We cover all <br /> businesses</h1>
                      <p className="text-xl opacioty-half">Focus on enjoying life while we focus on providing a <br /> cover for the things you love.</p>
                      <a href="" className="button button--pink mt-10 self-start"><span>Get Started</span></a>
                  </div>
                  <div className="image-layout order-first mt-10 lg:order-last"><img src={partner_portal_shot_2} alt="casava ai claims illustration image" /></div>
              </div>
          </div>
      </section>
      
      <Faq data1={data1} data2={data2}></Faq>
      <Banner></Banner>
      <Footer></Footer>
    </div>
  );
}

export default Business;
