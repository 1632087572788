import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";
import Cover from "../components/CreditLife/Cover";
import Banner from "../components/Common/Banner";
import partner_page_commission_shot from '../assets/partner-page-commission-shot.png';
import icon_partners_access from '../assets/icon-partners-access.png';
import icon_policy_health_cash from '../assets/icon-policy-health-cash.png';
import icon_policy_health_insurance from '../assets/icon-policy-health-insurance.png';
import icon_policy_business_insurance from '../assets/icon-policy-business-insurance.png';
import frameExperience from '../assets/frame-experience.jpg';
import apply_for_access from '../assets/apply-for-access.png';

function CreditLife() {

  let infoProps =  {
      "field1":"Apply for access",
      "desc1":"Access various levels of integration and" ,
      "desc2":"customization for your platform.",
      "img":icon_partners_access
  }

  return (
    <div>
      <Header></Header>

      <Cover></Cover>
      
        <section className="fature-section round-section light-lime--background">
            <div className="container modified-container">
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-10 items-center">
                    <div className="flex flex-col lg:ml-auto">
                        <h1 className="mb-6">It’s financial <br /> security for your <br /> business</h1>
                        <p className="text-xl">Your business remains sustainable when we protect it against uncontrollable life events</p>
                        <a href="https://forms.gle/gQ1WFYJr9cRXqYVR7" target="_blank" className="button button--pink mt-10 self-start"><span>Get Credit Life</span></a>
                    </div>
                    <div className="bg-image-layout order-first"></div>
                </div>
            </div>
        </section>

        <section className="health-perks-section credit-life-section">
            <div className="container">
                <div className="flex flex-col items-center justify-center">
                    <div className="w-full lg:w-2/3 lg:text-center">
                        <h1 className="mb-6">We also  provide perks <br /> for borrowers</h1>
                        <p className="text-xl">Borrowers can access insurance they need <br /> during their loan period</p>
                    </div>
                </div>
                <section>
                <div className="coverage-features coverage-features-two">
                    <div className="perk-item">
                        <img src={icon_policy_health_cash} />
                        <h5>Health Cash</h5>
                        <p>Gives cashback on every hospital visit due to accidents</p>
                        <a href="https://forms.gle/vm5EeqtiZw5iMVJcA" target="_blank" className="button button--pink mt-10 self-start"><span>File A Claim</span></a>
                    </div>
                    <div className="perk-item">
                        <img src={icon_policy_health_insurance} />
                        <h5>Health Insurance</h5>
                        <p>Provides affordable healthcare to customers</p>
                        <a href="https://forms.gle/vm5EeqtiZw5iMVJcA" target="_blank" className="button button--pink mt-10 self-start"><span>File A Claim</span></a>
                    </div>
                    <div className="perk-item">
                        <img src={icon_policy_business_insurance} />
                        <h5>Business Insurance</h5>
                        <p>Protects customer's workspace against flood, storm and other events</p>
                        <a href="https://forms.gle/vm5EeqtiZw5iMVJcA" target="_blank" className="button button--pink mt-10 self-start"><span>File A Claim</span></a>
                    </div>
                </div>
                </section>
            </div>
        </section>

        <section className="fature-section light-lime--background">
            <div className="container">
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20 items-center">
                    <div className="flex flex-col lg:ml-auto order-first">
                        <h1 className="mb-6">Earn up to 20% <br /> commission </h1>
                        <p className="text-xl">We’ve bundled our products with Credit life insurance. <br /> Now you can get value whenever you sell an insurance <br /> policy to your customers.</p>
                        <a href="https://forms.gle/gQ1WFYJr9cRXqYVR7" target="_blank" className="button button--black mt-10 self-start"><span>Partner with us</span></a>
                    </div>
                    <div className="image-layout order-first lg:order-last scale-img"><img src={partner_page_commission_shot} alt="casava ai claims illustration image" /></div>
                </div>
            </div>
        </section>

        <section className="claims-demo-section light-green-alt-400">
            <div className="container">
                <div className="grid grid-cols-1 gap-10 items-center lg:grid-cols-2">
                    <div className="demo-card anim-text">
                        <img src={frameExperience} />
                        <div className="pill-item">We celebrate claims</div>
                    </div>
                    <div className="claims-paid-in">
                        <h1 className="mb-6">
                            Fast and <br /> efficient claims <br /> process
                        </h1>
                        <p className="text-xl">Enjoy the fastest claims experience here. Once you make a claim, we attend to it immediately while you get paid in 48hours, or less.</p>
                        <a href="https://casava.com/claims" className="button button--black mt-10 self-start"><span>Find Out More</span></a>
                    </div>
                </div>
            </div>
        </section>

        <section className="fature-section integration-cover seamless-cover">
            <div className="container">
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20 items-center">
                    <div className="flex flex-col lg:ml-auto">
                    <div className="step active">
                        <p className="mb-6 text-xl">Swift signup process</p>
                        <p className="text">Our platform is designed to help you get life insurance with minimal hassle.</p>
                    </div>
                    <div className="step">
                        <p className="mb-6 text-xl">Seamless Integration</p>
                        <p className="text">Our platform is designed to help you get life insurance with minimal hassle.</p>
                    </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="fature-section black--background apply_for_access_bg">
            <img src={apply_for_access} alt="casava ai claims illustration image" />
            <div className="container modified-container">
                <div className="grid gap-10 grid-cols-1 lg:gap-20 items-center">
                    <div className="text-center">
                        <h1 className="mb-6">Apply for access</h1>
                        <p className="text">We are in the business of protecting lives and < br />businesses</p>
                        <a href="https://forms.gle/gQ1WFYJr9cRXqYVR7" target="_blank" className="button button--pink mt-10 self-start"><span>Get Credit Life From Us</span></a>
                    </div>
                </div>
            </div>
        </section>

      <Banner></Banner>
      <Footer></Footer>
    </div>
  );
}

export default CreditLife;
