import umbrellaCover from '../../assets/umbrella-cover.png';

function Banner() {
    return (
      <>
         {/* banner section  */}
        <section className="footer-cta">
            <div className="container">
                <div className="footer-cta__card px-12 py-12 md:px-20 md:py-16">
                    <h2 className="mb-4">Insurance that just works.</h2>
                    <p className="md:w-2/4 w-full">Sign up in 90 seconds with an affordable monthly <br /> subscription, starting at N600</p>
                    <a href="https://insurance.casava.co" className="button button--white mt-6" aria-label="Sign up for free"><span>Sign up for free</span></a>
                    <img src={umbrellaCover} className="footer-cta__umbrella hidden md:block" alt="umbrella cover" />
                </div>
            </div>
        </section>
  
      </>
    );
}
  
export default Banner;
  