import frameExperience from '../../assets/frame-experience.jpg';

function Experience() {
    return (
      <>
        
        {/* exeperience section  */}
        <section className="claims-demo-section light-green-alt-400">
            <div className="container">
                <div className="grid grid-cols-1 gap-10 items-center lg:grid-cols-2">
                    <div className="demo-card anim-text">
                        <img src={frameExperience} alt="We celebrate claims" width={450} />
                        <div className="pill-item">We celebrate claims</div>
                    </div>
                    <div className="claims-paid-in">
                        <h1 className="mb-6">
                            Claims paid in <br />
                            48hrs. Nothing more
                        </h1>
                        <p className="text-xl">Our claims process is hassle-free, instant, and <br /> fully digital. We pay claims within 48 hours <br /> of your approved application.</p>
                        <a href="/claims" className="button button--black mt-6" aria-label="Learn More about Claims"><span>Learn Claims</span></a>
                    </div>
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default Experience;
  