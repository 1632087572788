function ClaimProcess() {
    return (
      <>
   {/* claim process section  */}
   <section className="claims-steps-section">
   <div className="container">
       <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20 items-center">
           <div>
               <div className="step-item">
                   <div className="number-circle">1</div>
                   <p>Click the "Claim" button</p>
               </div>
               <div className="step-item">
                   <div className="number-circle">2</div>
                   <p>Tell us what happened</p>
               </div>
               <div className="step-item">
                   <div className="number-circle">3</div>
                   <p>Our claims engine runs a review</p>
               </div>
               <div className="step-item">
                   <div className="number-circle">
                       <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" svg-inline="" role="presentation" focusable="false" tabindex="-1">
                           <path d="M16 31.184c8.385 0 15.184-6.799 15.184-15.185S24.385.814 15.999.814.814 7.613.814 16 7.613 31.184 16 31.184z" fill="#5CBD75"></path>
                           <path
                               d="M15.999 31.251C7.574 31.251.746 24.423.746 16 .746 7.574 7.574.746 16 .746 24.423.746 31.25 7.574 31.25 16c0 8.424-6.828 15.252-15.252 15.252zm0-30.369C7.676.882.882 7.676.882 15.999c0 8.322 6.794 15.117 15.117 15.117 8.322 0 15.117-6.795 15.117-15.117 0-8.323-6.794-15.117-15.117-15.117z"
                               fill="#FFCD28"
                           ></path>
                           <path
                               fill-rule="evenodd"
                               clip-rule="evenodd"
                               d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16zM16 1.63C8.09 1.63 1.63 8.09 1.63 16c0 7.91 6.46 14.37 14.37 14.37 7.91 0 14.37-6.46 14.37-14.37 0-7.91-6.46-14.37-14.37-14.37z"
                               fill="#000"
                           ></path>
                           <path d="M10.988 15.889l3.44 3.502 6.566-7.004" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                       </svg>
                   </div>
                   <p>Your claim is evaluated and you get paid within 48 hours</p>
               </div>
           </div>
           <div className="flex flex-col lg:ml-auto order-first lg:order-last">
               <h1 className="mb-6">
                   Make a claim. <br />
                   No papers. <br />
                   No walk-ins.
               </h1>
               <p className="text-xl">File a claim anytime, anywhere. We get it approved. You get paid.</p>
               <a href="https://forms.gle/vm5EeqtiZw5iMVJcA" target="_blank" className="button button--pink mt-10 self-start"><span>Get Started</span></a>
           </div>
       </div>
   </div>
</section>
</>
    );
  }
  
  export default ClaimProcess;