function Cover() {
    return (
      <>
  
       {/* cover section  */}
       <section className="hero-section md:bg-pink-200 income-protection-hero">
            <div className="container">
                <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-2 md:gap-20">
                    <div>
                        <div className="pill-item bg-light-green-400 hidden md:block">Income Protection</div>
                        <h1>Get paid if you lose your job</h1>
                        <p className="mr-0 md:mr-10 text-lg md:text-xl">With Income Protection, you get paid a monthly salary for up to 6 months, if you lose your job</p>
                        <a href="https://insurance.casava.co/" className="button button--white mb-10 mt-5"><span>Try For Free</span></a>
                    </div>
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default Cover;
  