
function Cover(props) {

  const handleJoin = () => {
    props.setModal(true);
  }
    return (
      <>
         {/* cover section */}
         <section className="md:bg-pink-200 hero-section health-cash-hero">
            <div className="container">
                <div className="pill-item bg-green-400">Health Cash</div>
                <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-2 md:gap-20">
                    <div>
                        <h1>Get Cashback on hospital visits</h1>
                        <p className="mr-0 md:mr-10 text-lg md:text-xl">We'll cover the cost whenever you get healthcare due to accidents</p>
                        <a className="button button--black mt-6" onClick={() => handleJoin()} ><span>Join waitlist</span></a>
                    </div>
                </div>
            </div>
        </section>

      </>
    );
  }
  
  export default Cover;
  