
function InfoBanner(props) {
    return (
      <>
        {/* info banner section  */}
        <section className="text-cta-secton">
            <div className="container">
              <div className="flex flex-col items-center justify-center">
                <div className="w-full lg:w-2/3 lg:text-center">
                  <img src={props.infoProps.img} />
                  <h1 className="mb-6">{props.infoProps.field1} <br /> {props.infoProps.field2}</h1>
                  <p className="text-xl">{props.infoProps.desc1} <br/> {props.infoProps.desc2} <br /> {props.infoProps.desc3}</p>
                  <a href="mailto:partnerships@casava.com" className="button button--black mt-6"><span>Contact Us</span></a>
                </div>
              </div>
            </div>
          </section>
      
      </>
    );
  }
  
  export default InfoBanner;
  