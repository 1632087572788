import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";
import Cover from "../components/About/Cover";
import Banner from "../components/Common/Banner";
import Carousel from "../components/Common/Carousel";
import about_user_shot from '../assets/protected-frame.jpg';
import casava_stamp from '../assets/casava-stamp.png';
import journey_casava from '../assets/journey-casava.svg';
import journey_casava_responsive from '../assets/journey-casava-responsive.svg';
import icon_partners_access from '../assets/icon-partners-access.png';
import logo_license_casava_1 from '../assets/logo-CentralBank.png';
import logo_license_casava_2 from '../assets/logo-NAICOM.png';
import logo_license_casava_3 from '../assets/logo-NIA.png';
import about_video from '../assets/about-video.mp4';
import { useEffect,useState } from "react";

function About() {

let dynamicTextArr = ['good','sweet','delightful','smooth','joyful','enjoyable','good'];
let index = 0;
  const [dynamicText, setDynamicText] = useState(dynamicTextArr[0]);

  let infoProps =  {
      "field1":"Apply for access",
      "desc1":"Access various levels of integration and" ,
      "desc2":"customization for your platform.",
      "img":icon_partners_access
  }
useEffect(() => {
        // setInterval(function () {
        //     console.log('kjhfkjrh',index);
        //     setDynamicText(dynamicTextArr[index]);
        //     if(index < 6){
        //         index = index + 1;  

        //     }


          
        // }, 2000);

        const timer = setInterval(function() {
            if(index < 6){
                index = index + 1;  
                setDynamicText(dynamicTextArr[index]);

               
                console.log('kjhfkjrh',index,dynamicTextArr[index]);
            } else if(index == 6) {
                index = 0;
                console.log('kjhfkjrh',index,dynamicTextArr[index]);

                //  setDynamicText(dynamicTextArr[index]);
                 
            }
        },1000);

        return() => clearTimeout(timer);

    },[]);
  

  return (
    <div className="about-page-wrapper">
      <Header></Header>

      <Cover></Cover>
      
        <section className="fature-section stats-counter">
            <div className="container">
                <div className="grid grid-cols-1 items-center">
                    <div className="order-first">
                        <h5 className="mb-6">We've celebrated</h5>
                    </div>
                </div>
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-3">
                    <div className="card-about-stats">
                        <strong>95k+</strong>
                        <p>Customers</p>
                    </div>
               
                    <div className="card-about-stats">
                        <strong>5</strong>
                        <p>Strategic partners</p>
                    </div>

                    <div className="card-about-stats">
                        <strong>$16M</strong>
                        <p>In the Insurance coverage</p>
                    </div>
                </div>
            </div>
        </section>

        <section className="fature-section">
            <div className="container">
                <div className="grid grid-cols-1 items-center">
                    <div className="text-center order-first">
                        <h1 className="mb-6">
                            We are bringing  <br /> <span className="text-pink">{dynamicText}</span> things to life
                        </h1>
                    </div>
                </div>
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 card-about-cover">
                    <div className="card-about-exp">
                        <strong>We are making Insurance an enjoyable experience</strong>
                        <p>At Casava, we believe that you deserve to live life without worry</p>
                    </div>
               
                    <div className="card-about-exp">
                        <strong>We are bringing more good things to life, again</strong>
                        <p>When you experience insurance with us, you’ll never look at insurance the same way</p>
                    </div>
                </div>
            </div>
        </section>

        <section className="fature-section dark-green--background">
            <div className="container modified-container ">
                <div className="grid grid-cols-1">
                    <div className="order-first">
                        <h1 className="mb-6">We are making <br /> Insurance Relevant</h1>
                    </div>
                </div>
                <div className="grid grid-cols-1">
                    <Carousel></Carousel>
                </div>
            </div>
            <div className="casava-stamp">
                <img src={casava_stamp} alt="" />
            </div>
        </section>

        <section className="fature-section dark-pink--background no-margin">
            <div className="split-container">
                <div className="video-container">
                    <video width="1208" height="680" loop muted autoPlay>
                        <source src={about_video} type="video/mp4" />
                    </video>
                </div>
                <div className="video-container-text">
                    <h2 className="mb-6">We are putting a <br/> smile on the faces <br/> of Nigerians</h2>
                    <p>We cannot change or prevent bad things from happening but if <br/> you allow us, we will provide relief, a sense of closure, even a new <br/> beginning for you through our revolutionary claims and product <br/> benefits.
                    <br/><br/><br/>
                        <a href="https://youtu.be/ij55Ty8tOdY" target="_blank">
                            <strong>Watch how Casava helps people like you</strong>
                        </a>
                    </p>
                </div>
            </div>
        </section>
    
        <section className="fature-section leaf-petals--background">
            <div className="container">
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20 items-center">
                    <div className="flex flex-col lg:ml-auto order-first text-center">
                        <h1 className="mb-6">This is what <br/> <span className="text-pink">Casava</span> means </h1>
                        <p className="text-xl">Casava is a play on Cassava - a plant that is grown in tropical regions and emerging markets across the world. Nigeria happens to be the world's largest producer of Cassava and it’s the third most important food crop in the tropics, comprising 30-50 percent of all calories consumed in sub-Saharan Africa.</p>
                        <p className="text-xl">We have the opportunity to capture the essence of Cassava - bringing nourishment and prosperity to the people of Africa and emerging communities.</p>
                    </div>
                    <div className="image-layout order-first lg:order-last"><img src={about_user_shot} alt="" /></div>
                </div>
            </div>
        </section>

        <section className="fature-section">
            <div className="container">
                <div className="grid grid-cols-1 items-center">
                    <div className="text-center order-first">
                        <h1 className="mb-6">Discover our <br /> Roots</h1>
                    </div>
                    <div className="image-layout order-first lg:order-last hidden-small"><img src={journey_casava} alt="journey casava" /></div>
                    <div className="image-layout order-first lg:order-last visible-small"><img src={journey_casava_responsive} alt="journey casava" /></div>
                </div>
            </div>
        </section>
        
        <section className="license-cta">
            <div className="container">
                <div className="license-cta__card px-12 py-12 md:px-20 md:py-16">
                    <h2 className="mb-4">Licensed, and <br/> powered for Africa</h2>
                    <p className="md:w-2/4 w-full">We are a general and life microinsurance company <br/> licensed by the National Insurance Commission <br/> (NAICOM), registered in Nigeria, and built for the future <br/> of insurance in Africa.</p>
                    <ul className="license-list">
                        <li>
                            <img src={logo_license_casava_1} alt="" />
                        </li>
                        <li>
                            <img src={logo_license_casava_2} alt="" />
                        </li>
                        <li>
                            <img src={logo_license_casava_3} alt="" />
                        </li>
                    </ul>
                </div>
            </div>
        </section>

      <Banner></Banner>
      <Footer></Footer>
    </div>
  );
}

export default About;
