
function Cover() {
    return (
      <>
  
        {/* cover section  */}
        <section className="hero-section md:bg-pink-300 about-page">
        <div className="container">
            <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-2 md:gap-20">
                <div>
                    <h1>
                        Simplifying <br /> protection <br /> for you
                    </h1>
                    <p className="text-lg md:text-xl mr-0 md:mr-10">We provide insurance with a delightful user <br/> experience, amazing value, and instant benefits, <br/> to help you thrive and prosper.</p>
                    <a href="https://insurance.casava.co/auth/login/" className="button button--white mt-5 visible-small"><span>Start for free</span></a>
                </div>
            </div>
        </div>
    </section>
      </>
    );
  }
  
  export default Cover;
  