import React, { useEffect, useState } from "react";
import Header from "../components/Common/Header";
import Faq from "../components/Common/Faq";
import Banner from "../components/Common/Banner";
import Footer from "../components/Common/Footer";
import faqData from '../config/faq.json';
import Cover from "../components/HealthInsurance/Cover";
import HealthSteps from "../components/HealthInsurance/HealthSteps";
import Hospitals from "../components/HealthInsurance/Hospitals";
import HealthPerks from "../components/Common/HealthPerks"
import icon_health_insurance_steps from '../assets/icon-health-iinsurance-steps.png';
import icon_close from '../assets/icon-close.png';
import icon_user_avatar from '../assets/avatar.png';
import icon_check from '../assets/icon-check.png';
import icon_user_name from '../assets/icon-user-name.png';
import icon_user_email from '../assets/icon-user-email.png';
import icon_user_phone from '../assets/icon-user-name.png';
import icon_whatsapp from '../assets/icon-whatsapp.png';
import { useFormik } from "formik";
import axios from 'axios';

function HealthInsurance() {
  const [isAdded, setisAdded] = useState(false);
  const [isModal, setModal] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [isError, setisError] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [isInsurance, setisInsurance] = useState(false);

  let index  = Math.ceil(faqData["healthInsuranceData"]['rows'].length/2);

  let rows = faqData["healthInsuranceData"].rows;
  let data1 = {title:'',rows:[]};
  data1.rows = rows.slice(0,index);
  let data2 = {title:'',rows:[]};
  data2.rows = rows.slice(index,faqData["healthInsuranceData"]['rows'].length);

  let healthPerksInfo  = {
    "title" : "We’ve got your needs covered",
    "desc1" : "Focus on enjoying life while we focus on providing a",
    "desc2" : "cover for the things you love.",
    "perks_three" :[
      {"label":"Diagnostic services","desc":"Know the state of your health with diagnostic and preventive tests","img":icon_health_insurance_steps},
      {"label":"Immunization","desc":"Stay protected by getting vaccinated when needed","img":icon_health_insurance_steps},
      {"label":"Accident and emergency care","desc":"Get treated in case of accidents at home or at work","img":icon_health_insurance_steps},
      {"label":"Antenatal and postnatal care","desc":"Enjoy prenatal and postnatal care induction of labour, assisted delivery","img":icon_health_insurance_steps},
      {"label":"Dental care","desc":"Get primary dental healthcare- examinations and treatment","img":icon_health_insurance_steps},
      {"label":"Eye care","desc":"Get primary eye care- consultation, eye tests, and examination","img":icon_health_insurance_steps},
      {"label":"ENT services","desc":"Get surgeries and treatment for ENT(Ear, nose, and throat) diseases","img":icon_health_insurance_steps},
      {"label":"Surgical services","desc":"Get minor, intermediate and major surgeries done","img":icon_health_insurance_steps},
      {"label":"Physiotherapy services","desc":"Get access to physiotherapy sessions and enjoy specialist consultations","img":icon_health_insurance_steps}
      ],
      "page":'health-insurance'
  }

  const submitForm = (data) => {
    setisSuccess(false);
    setisError(false);
    setsuccessMessage("");
    seterrorMessage("")
    setisAdded(false);

    console.log('submitForm.......');
   
    let config = new Headers();
    config.append("Accept", "application/json");
    config.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    axios.post('https://api.dev.casava.co/api/local/subscribe-to-waitlist', data,config)
    .then(function (response) {
      console.log(response);
      setisSuccess(true);
      setisAdded(true);
      formik.resetForm();
    })
    .catch(function (error) {
      console.log(error);
      console.log(error.response.data.errors);
      console.log(error.response.data.message);
      setisError(true);
       seterrorMessage(
          error.response.data.message ? error.response.data.message : "Error while adding data"
        );
    });
  }
  
  const handleClose = () => {
    setModal(false);
    setisAdded(false);
    setisSuccess(false);
    setisError(false);
    setsuccessMessage("");
    seterrorMessage("");
    setisInsurance(false);
    formik.resetForm();
  }

   const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "Required";
    }
    if (!values.last_name) {
      errors.last_name = "Required";
    }

    if (!values.phone) {
      errors.phone = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      "product": "Health Insurance",
      "send_whatsapp": false
    },
    validate,
    onSubmit: (values) => {
      console.log(values);
      submitForm(values);
    },
  });



  

  return (
    <div>
        <Header></Header>
        <Cover setModal={setModal}></Cover>
        <HealthPerks healthPerksInfo={healthPerksInfo} showBtn={true} setModal={setModal} setisInsurance={setisInsurance}></HealthPerks>
        <HealthSteps></HealthSteps>
        <Hospitals setModal={setModal} setisInsurance={setisInsurance}></Hospitals>
        <Faq data1={data1} data2={data2}></Faq>
        <Banner></Banner>
        <Footer></Footer>

        {
          isModal && 

          <section className={isInsurance ? "modal-perks" : "modal-cover"}>                   
            <div className="modal modal-health-insurance">  
              <div className="bg-modal-health-insurance">  
                <span onClick={handleClose} className="modal-close">
                  <img src={icon_close} />  
                </span>
                <div className="form-section">
                  { isAdded ? 
                    <>
                      <div className="form-pseudo">
                        <img src={icon_user_avatar} />  
                        <h3>Enjoy premium healthcare <br/> with Casava.</h3>
                        <p>Be the first to try this seamless <br/> health insurance</p>
                        <span className="success-icon">
                          <img src={icon_check} className="form-icon-green-large" />
                        </span>
                        <h4>Success!</h4>
                        <p><small>Congratulations, you have joined the <br/> waiting list</small></p>
                        <a className="button button--pink" onClick={handleClose}><span>Done</span></a>
                      </div> 
                    </>
                    :
                    <form  name="health-insurance-form"
                      noValidate
                      onSubmit={formik.handleSubmit}>
                      <img src={icon_user_avatar} />  
                      <h3>Enjoy premium healthcare <br/> with Casava.</h3>
                      <p>Be the first to try this seamless <br/> health insurance</p>

                      {isError && <div>{errorMessage}</div>}
                      
                      <div className="field field-half first-child">
                        <img src={icon_user_name} className="form-placeholder-icon" />
                        <input type="text" name="first_name" id="first_name" placeholder="Firt Name" required                     
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.first_name} />
                        <label htmlFor="first_name">First Name</label>

                        <p className="danger-form-validation">
                        {formik.touched.first_name && formik.errors.first_name ? (
                          <>{formik.errors.first_name}</>
                        ) : null}
                      </p>

                      </div>
                      
                      <div className="field field-half last-child">
                        <img src={icon_user_name} className="form-placeholder-icon" />
                        <input type="text" name="last_name" id="last_name" placeholder="Last Name" required  
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.last_name} />
                        <label htmlFor="last_name">Last Name</label>

                        <p className="danger-form-validation">
                        {formik.touched.last_name && formik.errors.last_name ? (
                          <>{formik.errors.last_name}</>
                        ) : null} </p>
                      </div>

                      <div className="field field-full">
                        <img src={icon_user_email} className="form-placeholder-icon" />
                        <input type="email" name="email" id="email" placeholder="Email address" required 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}/>
                        <label htmlFor="email">Email address</label>

                        <p className="danger-form-validation">
                        {formik.touched.email && formik.errors.email ? (
                          <>{formik.errors.email}</>
                        ) : null} </p>
                      </div>

                      <div className="field field-full">
                        <img src={icon_user_phone} className="form-placeholder-icon" />
                        <input type="text" name="phone" id="phone" placeholder="Phone number" required  
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}/>
                        <label htmlFor="phone">Phone number</label>

                        <p className="danger-form-validation">
                        {formik.touched.phone && formik.errors.phone ? (
                          <>{formik.errors.phone}</>
                        ) : null} </p>
                      </div>

                      <div className="info"> <img src={icon_whatsapp} className="form-icon-green" /> We recommend you use a Whatsapp phone number</div>

                      {/* <input type="submit" className="button button--black" name="joinwaitlist" value="Join Waitlist" /> */}

                      <button type="submit"  className="button " value="Join Waitlist" >Join Waitlist</button>
                    </form>
                  }
                </div>
              </div>
            </div>
          </section>
        }
    </div>
  );
}

export default HealthInsurance;
