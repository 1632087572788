
function Cover() {
    return (
      <>
  
        {/* cover section  */}
        <section className="hero-section md:bg-pink-300 partners-page">
        <div className="container">
            <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-2 md:gap-20">
                <div>
                    <h1>
                        We want to see <br /> you prosper
                    </h1>
                    <p className="text-lg md:text-xl mr-0 md:mr-10">It’s why we use technology to provide insurance with <br/> instant benefits that will drive your business forward.</p>
                    <a href="mailto:partnerships@casava.com" className="button button--white mt-6"><span>Get in touch</span></a>
                </div>
            </div>
        </div>
    </section>
      </>
    );
  }
  
  export default Cover;
  