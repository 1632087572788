import Header from "../components/Common/Header";
import Faq from "../components/Common/Faq";
import Banner from "../components/Common/Banner";
import Footer from "../components/Common/Footer";
import { useState,useEffect } from "react";
import faqData from '../config/faq.json';
import Cover from "../components/IncomeProtection/Cover";
import InfoBanner from "../components/Common/InfoBanner";
import Coverage from "../components/IncomeProtection/Coverage";
import icon_income_protection from '../assets/icon-income-protection.png';

function IncomeProtection() {
    const [salaryAmount,setsalaryAmount] = useState(0);
    const [payablePremium,setPayablePremium] = useState(false);
    const [percentiles,setpercentiles] = useState(0.3);
    const [coverageRate,setcoverageRate] = useState(0.026);
    const [noOfMonths,setnoOfMonths] = useState(6);

    const [coverageAmt,setcoverageAmt] = useState(0);
    const [premiumAmt,setpremiumAmt] = useState(0);

    let index  = Math.ceil(faqData["incomeProtectionData"]['rows'].length/2);

    let rows = faqData["incomeProtectionData"].rows;
    let data1 = {title:'',rows:[]};
    data1.rows = rows.slice(0,index);
  
    let data2 = {title:'',rows:[]};
    data2.rows = rows.slice(index,faqData["incomeProtectionData"]['rows'].length);
    useEffect(() => {
        // Update the document title using the browser API
        var input = document.querySelector('input');
        input.addEventListener('input', function() {
            this.value = this.value.replace(/[^0-9]/g, '');
            var num = this.value.replace(/,/gi, "").split("").reverse().join("");
            this.value = RemoveRougeChar(num.replace(/(.{3})/g,"$1,").split("").reverse().join(""));
        

            handlChange(this.value);

        });

    });
   
    function RemoveRougeChar(convertString){
    
    
        if(convertString.substring(0,1) == ","){
            
            return convertString.substring(1, convertString.length)            
            
        }
        return convertString;
        
    }



    const coverageAmount = (selectedsalaryAmount,selectedPercent) => {
        let amt =   (selectedsalaryAmount * selectedPercent * noOfMonths );
        // let amt =   (selectedsalaryAmount * selectedPercent * noOfMonths ).toLocaleString('en', { maximumFractionDigits: 0 });
        if(amt>2000000){
            setPayablePremium(true)
        }else{
            setPayablePremium(false)
        }
        let capAmount = (amt>2000000?2000000:amt).toLocaleString('en', { maximumFractionDigits: 0 })
        setcoverageAmt(capAmount);
        // setcoverageAmt(amt);
    }

    const monthlyPayment = (selectedsalaryAmount,selectedPercent) => {
        let amt =   (selectedsalaryAmount * selectedPercent * coverageRate)
        // let amt =   (selectedsalaryAmount * selectedPercent * coverageRate).toLocaleString('en', { maximumFractionDigits: 0 })
        let capPremiumAmt = (amt>8667?8667:amt).toLocaleString('en', { maximumFractionDigits: 0 })
        setpremiumAmt(capPremiumAmt);

    }

    const handlChange = (amt) => {
        let updatedAmt = amt.replace(/,/g, '');
        setsalaryAmount(Number(updatedAmt));
        coverageAmount(Number(updatedAmt),Number(percentiles));
        monthlyPayment(Number(updatedAmt),Number(percentiles));
    }

    const handlePercentChange = (selectedPercentile) => {
        setpercentiles(Number(selectedPercentile));
        setsalaryAmount(Number(salaryAmount));
        coverageAmount(Number(salaryAmount),Number(selectedPercentile));
        monthlyPayment(Number(salaryAmount),Number(selectedPercentile));
        
    }
    

    let infoProps =  {
        "field1":"What's Income ","field2":"Protection?",
        "desc1":"Income Protection provides employees with a monthly income for up " ,
        "desc2": "to 6 months if their employment is terminated. You are qualified as ",
        "desc3": "long as you earn an income as an employee.",
        "img":icon_income_protection
    }


  return (
    <div>
        <Header></Header>

        <Cover></Cover>

        <InfoBanner infoProps={infoProps}></InfoBanner>

        {/* plan calculator section  */}
        <section className="estimate-section">
            <div className="container">
                <div className="grid gap-10 grid-cols-1 items-center lg:grid-cols-2 lg:gap-20">
                    <div className="flex flex-col">
                        <div className="lg:order-first mt-10 lg:mt-0">
                            <h1 className="mb-6">
                                Income Protection <br />
                                Calculator
                            </h1>
                            <p className="text-xl">Calculate how much we'll pay you for 6 months, if you lose your job.</p>
                        </div>
                        <div>
                            <div className="salary-input-field mt-0 lg:mt-12"><input name="salaryAmount" id="salaryAmount" type="text" defaultValue={salaryAmount}/></div>
                           {
                            payablePremium && (
                                <div className="payable-premium">
                                    <span>
                                    <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="#FFE8F2"/>
                                    <circle cx="12" cy="12" r="7.3" stroke="#FF6E9B" stroke-width="0.4"/>
                                    <path d="M11.6903 16V11.0909H12.4446V16H11.6903ZM12.0739 10.2727C11.9268 10.2727 11.8001 10.2227 11.6935 10.1225C11.5891 10.0224 11.5369 9.90199 11.5369 9.76136C11.5369 9.62074 11.5891 9.50036 11.6935 9.40021C11.8001 9.30007 11.9268 9.25 12.0739 9.25C12.2209 9.25 12.3466 9.30007 12.451 9.40021C12.5575 9.50036 12.6108 9.62074 12.6108 9.76136C12.6108 9.90199 12.5575 10.0224 12.451 10.1225C12.3466 10.2227 12.2209 10.2727 12.0739 10.2727Z" fill="#FF6E9B"/>
                                    </svg>
                                    </span>
                                    The maximum coverage is ₦2,000,000 (₦333,333.33 for 6 months) for a monthly premium of ₦8,667
                                </div>
                            )
                           }
                           
                            <p className="my-4 font-medium opacity-50">Percentage of your salary you want us to pay</p>
                            <div className="salary-percentiles">
                                <div className={percentiles == 0.3 ? 'active' : ''} onClick={() => handlePercentChange(0.3)}>30%</div>
                                <div className={percentiles == 0.5 ? 'active' : ''} onClick={() => handlePercentChange(0.5)}>50%</div>
                                <div className={percentiles == 0.75 ? 'active' : ''} onClick={() => handlePercentChange(0.75)}>75%</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col lg:ml-auto order-last">
                        <div className="mb-16">
                            <p className="text-xl mb-4">WE'LL PAY YOU</p>
                            <h1 className="get-amount">
                                ₦ {coverageAmt}
                                <svg width="75" height="67" viewBox="0 0 75 67" fill="none" xmlns="http://www.w3.org/2000/svg" svg-inline="" role="presentation" focusable="false" tabIndex="-1">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M24.167 2.832c-1.387-.14-2.684.157-3.87.81-.49.268-.812.929-.433 1.409 1.24 1.568.51 3.134.044 4.64-2.965 9.582-7.434 18.477-12.576 27.05-1.876 3.129-3.874 6.186-5.797 9.286-.261.421-.695.783-.255 1.442.44.657.952.19 1.363.317 3.117.965 4.416-1.322 5.968-3.18 7.851-9.402 13.161-20.124 16.565-31.83.623-2.143 1.03-4.333.936-6.573-.061-1.427-.433-2.748-1.945-3.371zM45.595 29.345c-.155-1.047-.604-1.557-1.596-1.46-.976.095-1.541.436-1.846 1.494-.65 2.262-2.364 3.866-3.933 5.504-5.524 5.77-11.461 11.111-17.317 16.536-.46.428-.96.839-.79 1.633.207.967.998.319 1.445.599 1.47.921 2.57.018 3.646-.8 5.59-4.246 11.285-8.368 15.97-13.668 1.536-1.738 3.021-3.513 4.325-5.44.577-.853.2-2.058 1.275-2.773.437-.29.505-1.247.178-1.946-.169-.364-.32-.818-.772-.794-.53.03-.358.606-.585 1.115zM63.532 49.717c-1.861-.376-3.122.066-4.462 1.095-2.583 1.98-5.737 2.849-8.824 3.756-4.08 1.2-8.224 2.124-12.444 2.712-1.069.148-2.116.491-3.153.812-.395.122-.867.319-.686.947.147.513.502.557.968.7 3.634 1.106 7.159-.061 10.671-.569 4.788-.691 9.494-1.861 13.934-3.89 1.63-.743 3.141-1.67 4.51-2.822.587-.494 1.05-1.045.851-1.91-.218-.95-.931-.864-1.365-.83z"
                                        fill="#FF147D"
                                    ></path>
                                </svg>
                            </h1>
                            <small className="opa">For 6 months</small>
                        </div>
                        <div className="mb-16">
                            <p className="text-xl mb-4">YOU'LL PAY A PREMIUM OF</p>
                            <h1>₦ {premiumAmt}<sub></sub></h1>
                            <small className="opa">Monthly</small>
                        </div>
                        <a href="https://insurance.casava.co" className="button button--pink text-center"><span>Sign Up</span></a>
                    </div>
                </div>
            </div>
        </section>
	
        <Coverage></Coverage>
        <Faq data1={data1} data2={data2}></Faq>
        <Banner></Banner>
        <Footer></Footer>
    </div>
  );
}

export default IncomeProtection;
